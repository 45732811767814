import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,


  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_REFERANCES_SUCCESS,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_ABOUT_SUCCESS,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,

  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,

  GET_ABOUT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_REFERANCES_SUCCESS,
  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,
  ADD_LESSONSTIME_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,
  DELETE_NEWS_SUCCESS,
  DELETE_SLIDER_SUCCESS,
  DELETE_POPUP_SUCCESS,
  DELETE_REFERANCES_SUCCESS,
  ADD_GALLERY_SUCCESS,
  DELETE_PRODUCTCATEGORY_SUCCESS,
  DELETE_GALLERY_SUCCESS,
  DELETE_COURSESCHEDULES_SUCCESS,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,
  DELETE_DYNAMICFORM_SUCCESS,











  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  GET_EDUCATION_SUCCESS,
  GET_SUBEDUCATION_SUCCESS,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_PRODUCTCATEGORY_SUCCESS,
  ADD_EDUCATION_SUCCESS,
  ADD_SUBEDUCATION_SUCCESS,
  ADD_SLIDER_SUCCESS,
  DELETE_SUBEDUCATION_SUCCESS,
  UPDATE_SUBEDUCATION_SUCCESS,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_BLOG_SUCCESS,
  GET_PRODUCTCATEGORY_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_SLİDERS_SUCCESS,
  GET_BLOGS_SUCCESS,
  ADD_BLOG_SUCCESS,
  ADD_PRODUCTCATEGORY_SUCCESS,
  DELETE_BLOG_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  GET_ABOUT_SUCCESS,
  ADD_SSS_SUCCESS,
  DELETE_SSS_SUCCESS,
  UPDATE_SSS_SUCCESS,
  GET_SSS_SUCCESS,



  ADD_NE_SUCCESS,
  DELETE_NE_SUCCESS,
  UPDATE_NE_SUCCESS,
  GET_NE_SUCCESS,



  ADD_NEWS_CATEGORY_SUCCESS,
  DELETE_NEWS_CATEGORY_SUCCESS,
  UPDATE_NEWS_CATEGORY_SUCCESS,
  GET_NEWS_CATEGORY_SUCCESS,

  DELETE_NEWS_COMMENT_SUCCESS,

  GET_NEWS_COMMENT_SUCCESS,

  ADD_ADVERTESING_FORM_SUCCESS,
  DELETE_ADVERTESING_FORM_SUCCESS,
  UPDATE_ADVERTESING_FORM_SUCCESS,
  GET_ADVERTESING_FORM_SUCCESS,

  ADD_ADVERTESING_CATEGORY_SUCCESS,
  DELETE_ADVERTESING_CATEGORY_SUCCESS,
  UPDATE_ADVERTESING_CATEGORY_SUCCESS,
  GET_ADVERTESING_CATEGORY_SUCCESS,

  ADD_FORUM_SUCCESS,
  DELETE_FORUM_SUCCESS,
  UPDATE_FORUM_SUCCESS,
  GET_FORUM_SUCCESS,




  ADD_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS,
  DELETE_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS,
  UPDATE_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS,
  GET_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS,
  
  ADD_MAINPAGE_CATEGORY_SUCCESS,
  DELETE_MAINPAGE_CATEGORY_SUCCESS,
  UPDATE_MAINPAGE_CATEGORY_SUCCESS,
  GET_MAINPAGE_CATEGORY_SUCCESS,

  GET_JOB_ADVERTISEMENT_SUCCESS,
  DELETE_JOB_ADVERTISEMENT_SUCCESS,
  ADD_JOB_ADVERTISEMENT_CATEGORY_SUCCESS,
  DELETE_JOB_ADVERTISEMENT_CATEGORY_SUCCESS,
  UPDATE_JOB_ADVERTISEMENT_CATEGORY_SUCCESS,
  GET_JOB_ADVERTISEMENT_CATEGORY_SUCCESS,
  
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  GET_COMPANY_SUCCESS,

  ADD_SONG_SUCCESS,
  DELETE_SONG_SUCCESS,
  UPDATE_SONG_SUCCESS,
  GET_SONG_SUCCESS,


  ADD_SONG_CATEGORY_SUCCESS,
  DELETE_SONG_CATEGORY_SUCCESS,
  UPDATE_SONG_CATEGORY_SUCCESS,
  GET_SONG_CATEGORY_SUCCESS,

  ADD_ARTICLEDAY_SUCCESS,
  DELETE_ARTICLEDAY_SUCCESS,
  UPDATE_ARTICLEDAY_SUCCESS,
  GET_ARTICLEDAY_SUCCESS,

  ADD_POOL_SUCCESS,
  DELETE_POOL_SUCCESS,
  UPDATE_POOL_SUCCESS,
  GET_POOL_SUCCESS,



  ADD_COMPANY_SUBCATEGORY_SUCCESS,
  DELETE_COMPANY_SUBCATEGORY_SUCCESS,
  UPDATE_COMPANY_SUBCATEGORY_SUCCESS,
  GET_COMPANY_SUBCATEGORY_SUCCESS,

  ADD_COMPANY_ADVERT_PRICE_SUCCESS,
  DELETE_COMPANY_ADVERT_PRICE_SUCCESS,
  UPDATE_COMPANY_ADVERT_PRICE_SUCCESS,
  GET_COMPANY_ADVERT_PRICE_SUCCESS,

  ADD_COMPANY_CATEGORY_SUCCESS,
  DELETE_COMPANY_CATEGORY_SUCCESS,
  UPDATE_COMPANY_CATEGORY_SUCCESS,
  GET_COMPANY_CATEGORY_SUCCESS,
  UPDATE_JOB_ADVERTISEMENT_SUCCESS,
  UPDATE_NEWSCOMMENT_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  forum: [],
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news: [],
  mega: [],
  teacher: [],
  dynamic: [],
  contact: [],
  authlist: [],
  workshop2: [],
  gallery: [],
  referances: [],
  subProject: [],
  lessonstime: [],
  classattendance: [],
  classes: [],
  ne: [],
  dynamicformextra: [],
  dynamicformextraextra: [],
  education: [],
  educationSubItem: [],

  productCategory: [],
  products: [],
  sliders: [],
  blogs: [],
  about: [],
  sss: [],
  songcategory: [],
  song: [],
  cons: [],
  companycategory: [],
  companysubcategory: [],
  company: [],
  pool: [],
  articleday: [],
  advertesingcategory: [],
  advertesingform: [],
  companyadvertprice: [],
  jobadvertisementcategory: [],
  mainpagecategory: [],
  newscategory: [],
  newscomment: [],
  jobadvertisement: [],
  jobadvertisementsubcategory: [],
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {

    case ADD_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        ne: [...state.ne, action.payload],
      }
    case GET_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        ne: action.payload,
      }


    case UPDATE_NE_SUCCESS:
      return {
        ...state,
        ne: state.ne.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_NE_SUCCESS:
      return {
        ...state,
        ne: state.ne.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case ADD_NE_SUCCESS:
      return {
        ...state,
        ne: [...state.ne, action.payload],
      }
    case GET_NE_SUCCESS:
      return {
        ...state,
        ne: action.payload,
      }


    case UPDATE_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        newscategory: state.newscategory.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        newscategory: state.newscategory.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }



      case GET_NEWS_COMMENT_SUCCESS:
        return {
          ...state,
          newscomment: action.payload,
        }

        case DELETE_NEWS_COMMENT_SUCCESS:
          return {
            ...state,
            newscomment: state.newscomment.filter(
              user => user.ID.toString() !== action.ID.toString()
            ),
          }
    


    case ADD_MAINPAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        mainpagecategory: [...state.mainpagecategory, action.payload],
      }
    case GET_MAINPAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        mainpagecategory: action.payload,
      }


    case UPDATE_MAINPAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        mainpagecategory: state.mainpagecategory.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_MAINPAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        mainpagecategory: state.mainpagecategory.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }


    case ADD_FORUM_SUCCESS:
      return {
        ...state,
        forum: [...state.forum, action.payload],
      }
    case GET_FORUM_SUCCESS:
      return {
        ...state,
        forum: action.payload,
      }


    case UPDATE_FORUM_SUCCESS:
      return {
        ...state,
        forum: state.forum.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_FORUM_SUCCESS:
      return {
        ...state,
        forum: state.forum.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }





      case ADD_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementsubcategory: [...state.jobadvertisementsubcategory, action.payload],
        }
      case GET_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementsubcategory: action.payload,
        }
  
  
      case UPDATE_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementsubcategory: state.jobadvertisementsubcategory.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_JOB_ADVERTISEMENT_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementsubcategory: state.jobadvertisementsubcategory.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  




      case ADD_JOB_ADVERTISEMENT_CATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementcategory: [...state.jobadvertisementcategory, action.payload],
        }
      case GET_JOB_ADVERTISEMENT_CATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementcategory: action.payload,
        }
  
  
      case UPDATE_JOB_ADVERTISEMENT_CATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementcategory: state.jobadvertisementcategory.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_JOB_ADVERTISEMENT_CATEGORY_SUCCESS:
        return {
          ...state,
          jobadvertisementcategory: state.jobadvertisementcategory.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  
  

        case GET_JOB_ADVERTISEMENT_SUCCESS:
          return {
            ...state,
            jobadvertisement: action.payload,
          }
    
          
          case UPDATE_NEWSCOMMENT_SUCCESS:
            return {
              ...state,
              newscomment: state.newscomment.map(x =>
                x.ID == action.payload.ID
                  ? { x, ...action.payload }
                  : x
              ),
            }

      case UPDATE_JOB_ADVERTISEMENT_SUCCESS:
        return {
          ...state,
          jobadvertisement: state.jobadvertisement.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

          case DELETE_JOB_ADVERTISEMENT_SUCCESS:
            return {
              ...state,
              jobadvertisement: state.jobadvertisement.filter(
                user => user.ID.toString() !== action.ID.toString()
              ),
            }
      
      



      case ADD_ADVERTESING_CATEGORY_SUCCESS:
        return {
          ...state,
          advertesingcategory: [...state.advertesingcategory, action.payload],
        }
      case GET_ADVERTESING_CATEGORY_SUCCESS:
        return {
          ...state,
          advertesingcategory: action.payload,
        }
  
  
      case UPDATE_ADVERTESING_CATEGORY_SUCCESS:
        return {
          ...state,
          advertesingcategory: state.advertesingcategory.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_ADVERTESING_CATEGORY_SUCCESS:
        return {
          ...state,
          advertesingcategory: state.advertesingcategory.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  







      case ADD_ADVERTESING_FORM_SUCCESS:
        return {
          ...state,
          advertesingform: [...state.advertesingform, action.payload],
        }
      case GET_ADVERTESING_FORM_SUCCESS:
        return {
          ...state,
          advertesingform: action.payload,
        }
  
  
      case UPDATE_ADVERTESING_FORM_SUCCESS:
        return {
          ...state,
          advertesingform: state.advertesingform.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_ADVERTESING_FORM_SUCCESS:
        return {
          ...state,
          advertesingform: state.advertesingform.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }





      case ADD_ARTICLEDAY_SUCCESS:
        return {
          ...state,
          articleday: [...state.articleday, action.payload],
        }
      case GET_ARTICLEDAY_SUCCESS:
        return {
          ...state,
          articleday: action.payload,
        }
  
  
      case UPDATE_ARTICLEDAY_SUCCESS:
        return {
          ...state,
          articleday: state.articleday.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_ARTICLEDAY_SUCCESS:
        return {
          ...state,
          articleday: state.articleday.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }



    case ADD_POOL_SUCCESS:
      return {
        ...state,
        pool: [...state.pool, action.payload],
      }
    case GET_POOL_SUCCESS:
      return {
        ...state,
        pool: action.payload,
      }


    case UPDATE_POOL_SUCCESS:
      return {
        ...state,
        pool: state.pool.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_POOL_SUCCESS:
      return {
        ...state,
        pool: state.pool.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }









    case ADD_COMPANY_ADVERT_PRICE_SUCCESS:
      return {
        ...state,
        companyadvertprice: [...state.companyadvertprice, action.payload],
      }
    case GET_COMPANY_ADVERT_PRICE_SUCCESS:
      return {
        ...state,
        companyadvertprice: action.payload,
      }


    case UPDATE_COMPANY_ADVERT_PRICE_SUCCESS:
      return {
        ...state,
        companyadvertprice: state.companyadvertprice.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_COMPANY_ADVERT_PRICE_SUCCESS:
      return {
        ...state,
        companyccompanyadvertpriceategory: state.companyadvertprice.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }




    case ADD_SONG_SUCCESS:
      return {
        ...state,
        song: [...state.song, action.payload],
      }
    case GET_SONG_SUCCESS:
      return {
        ...state,
        song: action.payload,
      }


    case UPDATE_SONG_SUCCESS:
      return {
        ...state,
        song: state.song.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_SONG_SUCCESS:
      return {
        ...state,
        song: state.song.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }








    case ADD_SONG_CATEGORY_SUCCESS:
      return {
        ...state,
        songcategory: [...state.songcategory, action.payload],
      }
    case GET_SONG_CATEGORY_SUCCESS:
      return {
        ...state,
        songcategory: action.payload,
      }


    case UPDATE_SONG_CATEGORY_SUCCESS:
      return {
        ...state,
        songcategory: state.songcategory.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_SONG_CATEGORY_SUCCESS:
      return {
        ...state,
        songcategory: state.songcategory.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }




      case ADD_COMPANY_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          companysubcategory: [...state.companysubcategory, action.payload],
        }
      case GET_COMPANY_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          companysubcategory: action.payload,
        }
  
  
      case UPDATE_COMPANY_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          companysubcategory: state.companysubcategory.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      case DELETE_COMPANY_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          companysubcategory: state.companysubcategory.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  



    case ADD_COMPANY_CATEGORY_SUCCESS:
      return {
        ...state,
        companycategory: [...state.companycategory, action.payload],
      }
    case GET_COMPANY_CATEGORY_SUCCESS:
      return {
        ...state,
        companycategory: action.payload,
      }


    case UPDATE_COMPANY_CATEGORY_SUCCESS:
      return {
        ...state,
        companycategory: state.companycategory.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_COMPANY_CATEGORY_SUCCESS:
      return {
        ...state,
        companycategory: state.companycategory.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }






    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        company: [...state.company, action.payload],
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
      }


    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: state.company.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        company: state.company.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }





    case DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: state.dynamicformextraextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: state.dynamicformextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        education: state.education.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: state.productCategory.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }


    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_ABOUT_SUCCESS:
      return {
        ...state,
        about: state.about.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case DELETE_USER_SUCCESS:
      return {
        ...state,
        forum: state.forum.filter(
          forum => forum.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_COURSESCHEDULES_SUCCESS:
      return {
        ...state,
        workshopSession: state.workshopSession.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: state.gallery.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: state.productCategory.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    case UPDATE_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: state.dynamic.map(x =>
          x.ID == action.educationSubItem.ID
            ? { x, ...action.payload }
            : x
        ),
      }



    case DELETE_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: state.educationSubItem.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case ADD_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: [...state.educationSubItem, action.payload],
      }


    case ADD_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: [...state.sliders, action.payload],
      }

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
      }


    case ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        education: [...state.education, action.payload],
      }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
      }

    case DELETE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SSS_SUCCESS:
      return {
        ...state,
        sss: state.sss.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_MEGA_SUCCESS:
      return {
        ...state,
        mega: state.mega.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case ADD_MEGA_SUCCESS:
      return {
        ...state,
        mega: [...state.mega, action.payload],
      }



    case GET_ABOUT_SUCCESS:
      return {
        ...state,
        about: action.payload,
      }
    case GET_SSS_SUCCESS:
      return {
        ...state,
        sss: action.payload,
      }


    case GET_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: action.payload,
      }

    case GET_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
      }


    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }


    case GET_SLİDERS_SUCCESS:
      return {
        ...state,
        sliders: action.payload,
      }


    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload,
      }
    case GET_EDUCATION_SUCCESS:
      return {
        ...state,
        education: action.payload,
      }

    case GET_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: action.payload,
      }

    case GET_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: action.payload,
      }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      }

    case GET_CLASSATTENDANCE_SUCCESS:
      return {
        ...state,
        classattendance: action.payload,
      }

    case UPDATE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: [...state.dynamic, action.payload],
      }

    case ADD_SSS_SUCCESS:
      return {
        ...state,
        sss: [...state.sss, action.payload],
      }
    case ADD_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: [...state.lessonstime, action.payload],
      }

    case GET_TEACHERWORKSHOP_SUCCESS:
      return {
        ...state,
        lessonstime: action.payload,
      }


    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }

    case UPDATE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SSS_SUCCESS:
      return {
        ...state,
        sss: state.sss.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case GET_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: action.payload,
      }

    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }


    case GET_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: action.payload,
      }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }



    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: action.payload,
      }


    case UPDATE_SUCCESS_GALLERY:
      console.log("deneme123", action.payload)
      return {
        ...state,
        gallery: state.gallery.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_SUCCESS_MEGA:
      console.log("deneme123", action.payload)
      return {
        ...state,
        mega: state.mega.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }



    case UPDATE_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: state.workshop2.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_NEWS:
      return {
        ...state,
        news: state.news.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: state.contact.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_PROJECT:
      return {
        ...state,
        projects: state.projects.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        workshop: state.workshop.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_SUCCESS_TEACHER:
      return {
        ...state,
        teacher: state.teacher.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: state.workshopsessionMega.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_COURSESCHEDULES:
      console.log("lksdnfsd", state.workshopSession)
      return {
        ...state,
        workshopSession: state.workshopSession.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup: action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case ADD_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: [...state.referances, action.payload],
      }

    case ADD_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: [...state.productCategory, action.payload],
      }

    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        blogs: [...state.blogs, action.payload],
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        forum: state.forum.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }


    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }



    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts

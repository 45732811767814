import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from 'react-dropzone-uploader';

import { Name, Status, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const ContactsList = props => {

  //meta title
  document.title = "Anketler | Berlin ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [getAnswer, setAnswer] = useState([]);
  const [getPoolID, setPoolID] = useState(null);

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Pool_Question_Text) || "",
   
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Adınızı Giriniz"),
     
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Pool_Question_Text:values.name,
          MainPage_Category_ID: getCategoryID,
          Pool_IsActive: getIsActive,
        //  Pool_Img : getFileName
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Pool_Question_Text:values["name"],
          MainPage_Category_ID: getCategoryID,
          Pool_IsActive: getIsActive,
        //  Pool_Img : getFileName
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getEditForm, setEditForm] = useState(false);
  const [getActive, setActive] = useState(false);
  const [getCategoryID, setCategoryID] = useState(false);
  const [getCategory, setCategory] = useState([]);
  const [getFileName, setFileName] = useState(null);

  const _addAnswer = () => {


    getFileSubMediaAdd.map((item, index) => {
      const newUser = {
        Education_SubItem_ID: getSubMediaID,
        EducationSubItem_Media_Type: item.fileType,
        EducationSubItem_Media_Link: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addSubEducationMediaDiz/send', newUser)

      dispatch(onGetDynamicForm());

      toggleMedia();
    })
  }

  const _addMedia = (item) => {
    setAnswer(item.Pool_Answer)
    setPoolID(item.ID)
    setModalMedia(!modalMedia);
  }

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getMainPage_CategoryBerlin/select/`)
      .then((res) => {
        setCategory(res.data)
      })

   
  }




  useEffect(() => {
    _getAuthData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Resim",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Pool_Img ? (
              <div className="avatar-xl">
               {cellProps.Pool_Question_Text != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Pool_Question_Text.charAt(0)}
                </span> : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Pool_Img}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },


      {
        Header: "Seçenekler",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Pool_Answer != null ? cellProps.Pool_Answer.length > 0 ? (
               cellProps.Pool_Answer.map((item, index) => (
                  <div key={index}>
                      <a>{item.Pool_Answer_Text}</a>
                  </div> 
              ))
        
            ) : (
              <div>
                 <a>Seçenek Yok</a>
              </div>
            ) : null}
          </>
        ),
      },

     
      {
        Header: "Form Adı",
        accessor: "Pool_Question_Text",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

       
      {
        Header: "Kategori Adı",
        accessor: "MainPage_Category.MainPage_Category_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
   
     
   
      {
        Header: "Durum",
        accessor: "Pool_IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
    
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
                <Link
              
              onClick={() => _addMedia(cellProps.row.original)}
                className="text-danger"
                state={{ _item: cellProps.row.original }}
              >
              
                Seçenek Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetDynamicForm());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

    setIsActive(user.Pool_IsActive)
    setCategoryID(user.MainPage_Category_ID)
    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory= arg => {
    setCategoryID(arg.target.value)
  }

  const [inputs, setInputs] = useState([]);
  const [existingData, setExistingData] = useState(getAnswer);

  // Yeni input ekleme
  const addInput = () => {
    setInputs([...inputs, { id: Math.random().toString(36).substr(2, 9), value: "" }]);
    
  };

  // Input değişimlerini yakalama
  const handleInputChange = (id, value) => {
    setInputs((prev) =>
      prev.map((input) => (input.id === id ? { ...input, value } : input))
    );
  };

  // Yeni inputları kaydetme
  const saveInputs = () => {
    console.log("New Inputs: ", inputs); // Post edilecek veriler
    // Burada POST işlemi yapılabilir
   
    inputs.map((item, index) => {
      const newUser = {
        Pool_ID: getPoolID,
        Pool_Answer_Text: item.value,
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/postPoolAnswer/send', newUser)

      setTimeout(() =>   dispatch(onGetDynamicForm()), 1000)
    

      toggleMedia();
    })

  };

  // Mevcut veriyi silme
  const deleteExistingData = (id) => {
    //setExistingData(existingData.filter((item) => item.id !== id));
    try {

      axios.delete(`https://getjob.stechomeyazilim.info:5101/deletePoolAnswer/${id}`).then(async (res) => {

        setTimeout(() =>   dispatch(onGetDynamicForm()), 1000)

        toggleMedia();

      })
    } catch (error) {
      //yield put(updateUserFail(error))
    }

  };


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileName(result.file.filename)
        }
      }
    }
  }


  // Yeni eklenen inputları silme
  const deleteInput = (id) => {
    setInputs(inputs.filter((input) => input.id !== id));
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

<Modal isOpen={modalMedia} toggle={toggleMedia}>
      <ModalHeader toggle={toggleMedia} tag="h4">
        {!!isEdit ? "Düzenle" : "Seçenek Ekle"}
      </ModalHeader>
      <ModalBody>
        <Row>
          {/* Mevcut veriler */}
          {getAnswer.map((item, index) => (
            <Col key={index} md={12} className="mb-3 d-flex align-items-center">
              <div className="flex-grow-1">
                <a>{item.Pool_Answer_Text}</a>
              </div>
              <Button
                color="danger"
                className="ms-3"
                onClick={() => deleteExistingData(item.ID)}
              >
                Sil
              </Button>
            </Col>
          ))}

          {/* Dinamik inputlar */}
          {inputs.map((input) => (
            <Col key={input.id} md={12} className="mb-3 d-flex align-items-center">
              <Input
                type="text"
                placeholder="Seçenek Girin"
                value={input.value}
                onChange={(e) => handleInputChange(input.id, e.target.value)}
              />
              <Button
                color="danger"
                className="ms-3"
                onClick={() => deleteInput(input.id)}
              >
                Sil
              </Button>
            </Col>
          ))}

          {/* Input ekleme butonu */}
          <Col md={12} className="mb-3">
            <Button color="primary" onClick={addInput}>
              + Yeni Seçenek Ekle
            </Button>
          </Col>


          {/* Kaydet butonu */}
          <Col md={12} className="text-end">
            <Button color="success" onClick={saveInputs}>
              Seçenekleri Kaydet
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="ANKETLER" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddDynamicForm={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Anket Formu Düzenle" : "Anket Form Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Anket Adı</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Form Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                        
                             <div className="mb-3">
                                                    <Label className="form-label">Anasayfa Kategorisi Seçiniz</Label>
                                                    <Input
                                                      name="paymentStatus"
                                                      type="select"
                                                      className="form-select"
                                                      defaultValue={getCategoryID}
                                                      onChange={(text) => _setChoiceCategory(text)}
                                                      value={
                                                        getCategoryID
                                                      }>
                                                        <option value="">Lütfen Seçim Yapınız</option>
                      
                                                      {getCategory.map((item, index) => (
                                                        <option value={item.ID} key={item.ID}>{item.MainPage_Category_Title}</option>
                                                      ))}
                      
                                                    </Input>
                                                   
                                                  </div>

                                                  <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatusBanner}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*"
                            />
                          </div>

                            <div className="d-flex">
                    <Label className="form-label">Aktif Mi?</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                      
                       
                        </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
